<template>
  <a-upload
    class="iss-upload"
    list-type="picture"
    v-bind="$attr"
    :data="file => ({ fileName: file })"
    :action="action"
    :headers="headers"
    :before-upload="beforeUpload"
    @change="handleChange"
    v-model:file-list="fileList"
  >
    <template v-if="listType === 'text'">
      <a-button>
        <template #icon>
          <loading-outlined v-if="loading" />
          <upload-outlined v-else />
        </template>
        上传视频
      </a-button>
    </template>
  </a-upload>
</template>

<script>
import { Upload } from 'ant-design-vue';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons-vue';
import errorMsg from 'ismartek-error-message';
import { setQKState } from '@/qiankun/appStore';
import { local } from '@/utils/storage';

export default {
  components: {
    AUpload: Upload,
    LoadingOutlined,
    UploadOutlined,
  },
  props: {
    value: String,
    listType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    // console.log(local.get('TOKEN'))
    // const { token, tenant } = this.$store.state.account;
    return {
      fileList: [],
      loading: false,
      action: `${process.env.VUE_APP_API_SERVER}/file/upload?bizType=video&isSingle=true`,
      headers: { token: local.get('TOKEN'), tenant: local.get('TENANT') },
    };
  },
  methods: {
    beforeUpload(file, fileList) {
      const isJpgOrPng = ['video/mp4', 'video/ogg'].includes(file.type);
      if (!isJpgOrPng) {
        this.$message.error('只能上传 mp4/ogg 格式的视频!');
      }
      this.fileList = fileList;
      // const isLt1M = file.size / 1024 / 1024 < 1;
      // if (!isLt1M) {
      //   this.$message.error('图片大小不能超过 1MB!');
      // }
      return isJpgOrPng;
    },
    handleChange({ file, fileList }) {
      this.fileList = fileList;
      if (this.fileList.length === 0) {
        this.$emit('update:value', '');
      }
      switch (file.status) {
        case 'uploading':
          this.loading = true;
          break;
        case 'done':
          this.loading = false;
          if (file.response.code === 0) {
            this.$message.success('上传成功！');
            this.$emit('update:value', file.response.data.dataUrlManage);
          } else {
            errorMsg[file.response.code]
              ? this.$message.error(errorMsg[file.response.code])
              : this.$message.error(file.response.msg);
            // 40001:会话超时，请重新登录
            if ([2001, 2002, 2003, 2004, 2005].includes(file.response.code)) {
              local.clear();
              setQKState({ noToken: true });
            }
          }
          break;
        case 'error':
          this.loading = false;
          this.$message.error('上传失败！');
          break;
        default:
          break;
      }
    },
    handleClickByDelete(e) {
      e.stopPropagation();
      this.$emit('update:value', '');
    },
  },
};
</script>

<style lang="less" scoped>
.iss-upload.ant-upload-picture-card-wrapper {
  display: block;
  :deep(.ant-upload.ant-upload-select-picture-card) {
    margin-bottom: 1px;
    border: 2px dashed @primary-3;
    &:hover {
      border-color: @primary-5;
    }
    .upload-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86px;
      height: 86px;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      .img-delete {
        position: absolute;
        top: -3px;
        right: -4px;
        z-index: 10;
      }
    }
    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
.has-error .iss-upload :deep(.ant-upload.ant-upload-select-picture-card) {
  border-color: @error-color;
}
</style>
