<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <!-- todo 需要补齐  -->
        <template #pushStatus="{ record }">
          <div v-if="record.pushStatus == 0">未发布</div>
          <div v-else-if="record.pushStatus == 1">已发布</div>
        </template>
      </grid>
    </div>
  </div>

  <iss-edit
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tag, message } from 'ant-design-vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import contactApi from '@/api/contact.js';
import informationApi from '@/api/information.js';
import issEdit from './components/edit';
// import classifyApi from "@/api/classify";

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    issEdit,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });
    // const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });

    return {
      gridRef,
      store,
      ...toRefs(state),
      items: [
        { key: 'dataName', label: '资料名称' },

        // {
        //   key: 'dataLevelOneName',
        //   label: '资料目录(一级)',
        //   type: 'select',
        //   dataset: [
        //     { label: '男', value: '男' },
        //     { label: '女', value: '女' },
        //   ],
        // },
        // {
        //   key: 'dataLevelTwoName',
        //   label: '资料目录(二级)',
        //   type: 'select',
        //   dataset: [
        //     { value: 'unaudited', label: '已发布' },
        //     { value: 'approve', label: '未发布' },
        //   ],
        // },
        // {
        //   key: 'dataLevelThreeName',
        //   label: '资料目录(三级)',
        //   type: 'select',
        //   dataset: [
        //     { value: '0', label: '不接受' },
        //     { value: '1', label: '接受' },
        //   ],
        // },
        {
          key: 'ataType',
          label: '资料类型',
          type: 'select',
          dataset: [
            { value: 'PDF', label: '文档' },
            { value: 'MP4', label: '视频' },
          ],
        },
        {
          key: 'pushStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已发布' },
            { value: '0', label: '未发布' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'dataLevelOneName',
          title: '资料类型(一级)',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'dataLevelTwoName',
          title: '资料类型(二级)',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'dataLevelThree',
          title: '资料类型(三级)',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'dataName',
          title: '资料名称',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'dataType',
          title: '资料形式',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'pushStatus',
          title: '发布状态',
          width: 120,
          slots: { customRender: 'pushStatus' },
          ellipsis: true,
        },
        {
          dataIndex: 'sort',
          title: '排序',
          width: 120,
          slots: { customRender: 'sort' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 80,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },

        {
          type: 'isPublish',
          label: '发布',
          icon: 'ExportOutlined',
          permission: 'agenda:publish',
          fnClick: () => {
            informationApi.informationPublish('', '').then(() => {
              message.success('发布成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'agenda:update',
          fnClick: record => {
            state.visible = true;
            let id = record.id;
            informationApi
              .getfindById('informations:updata', { id })
              .then(res => {
                state.activeItem = { ...res };
              });
          },
        },
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'contact:view',
          fnClick: record => {
            // 这个状态控制
            // 点击查看时传一个查看的状态去弹窗，然后弹窗判断该状态是true，则disble input
            state.visible = true;
            state.activeItem = { ...record, disabled: true };
          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            console.log('id', id);
            informationApi.delete('resources:delete', { id: id }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      // todo informationApi.informationUrl
      url: contactApi.pageUrl,
      gridHeight: document.body.clientHeight - 386,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');
        informationApi[type](`stand:${type}`, value).then(data => {
          console.log('data', data);
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
          setTimeout(() => {}, 1500);
        });
        // 保存失败后 恢复保存按钮
        setTimeout(() => {}, 1500);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
