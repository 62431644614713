<template>
  <a-upload
    class="iss-upload"
    :list-type="listType"
    v-bind="$attr"
    :data="file => ({ fileName: file })"
    :action="action"
    :headers="headers"
    :before-upload="beforeUpload"
    @change="handleChange"
    v-model:file-list="fileList"
  >
    <template v-if="listType === 'text'">
      <a-button class="uploadBut" v-if="!value" type="text">
        <template #icon>
          <loading-outlined v-if="loading" />
          <upload-outlined v-else />
        </template>
        上传文档
      </a-button>
    </template>
    <template v-else>
      <div v-if="value" class="upload-img">
        <img :src="value" />
        <delete-two-tone
          class="img-delete"
          twoToneColor="#f5222d"
          @click="handleClickByDelete"
        />
      </div>
      <div v-else>
        <loading-outlined v-if="loading" />
        <plus-outlined v-else />
        <div class="ant-upload-text">上传</div>
      </div>
    </template>
  </a-upload>
</template>

<script>
import { Upload } from 'ant-design-vue';
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  DeleteTwoTone,
} from '@ant-design/icons-vue';
import { nextTick } from '@vue/runtime-core';

import errorMsg from 'ismartek-error-message';
import { setQKState } from '@/qiankun/appStore';
import { local } from '@/utils/storage';

export default {
  components: {
    AUpload: Upload,
    LoadingOutlined,
    PlusOutlined,
    UploadOutlined,
    DeleteTwoTone,
  },
  props: {
    value: String,
    resourceSize: String,
    documentName: String,
    listType: {
      type: String,
      default: 'text',
    },
    check1M: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    // const { token, tenant } = this.$store.state.account;
    const tenant = local.get('TENANT');
    // tenant && (config.headers.tenant = tenant);
    const token = local.get('TOKEN');
    return {
      fileList: [],
      loading: false,
      action: `${process.env.VUE_APP_API_SERVER}/file/upload?bizType=pdf&isSingle=true`,
      headers: { token, tenant },
    };
  },
  mounted() {
    this.value &&
      (this.fileList = [
        {
          name: this.documentName,
          response: {
            data: {
              url: this.value,
              filename: this.documentName,
            },
          },
        },
      ]);
  },
  methods: {
    beforeUpload(file) {
      //   'image/jpeg',   'image/png',

      const isJpgOrPng = ['application/pdf'].includes(file.type);
      // JPG/JPEG/PNG 格式的图片或
      if (!isJpgOrPng) {
        nextTick(() => (this.fileList = []));
        this.$message.error('只能上传 PDF 文件!');
        return false;
      }
      // const isLt1M = file.size / 1024 / 1024 < 1;
      // if (!isLt1M && this.check1M) {
      //   this.$message.error('图片大小不能超过 1MB!');
      //   return false;
      // }
      return true;
    },
    handleChange({ file, fileList }) {
      if (fileList.length === 0) {
        this.$emit('update:value', '');
      }
      switch (file.status) {
        case 'uploading':
          this.loading = true;
          break;
        case 'done':
          this.loading = false;
          if (file.response.code === 0) {
            this.$message.success('上传成功！');
            this.$emit('update:value', file.response.data.dataUrlManage);
            this.$emit('update:resourceSize', file.response.data.fileSize);
            this.$emit(
              'update:documentName',
              file.response.data.submittedFileName
            );
          } else {
            errorMsg[file.response.code]
              ? this.$message.error(errorMsg[file.response.code])
              : this.$message.error(file.response.msg);
            // 40001:会话超时，请重新登录
            if ([2001, 2002, 2003, 2004, 2005].includes(file.response.code)) {
              local.clear();
              setQKState({ noToken: true });
            }
          }
          break;
        case 'error':
          this.loading = false;
          this.$message.error('上传失败！');
          break;
        default:
          break;
      }
    },
    handleClickByDelete(e) {
      e.stopPropagation();
      this.$emit('update:value', '');
      this.$emit('update:resourceSize', '');
    },
  },
};
</script>

<style lang="less" scoped>
.iss-upload.ant-upload-picture-card-wrapper {
  display: block;
  :deep(.ant-upload.ant-upload-select-picture-card) {
    margin-bottom: 1px;
    border: 2px dashed @primary-3;
    &:hover {
      border-color: @primary-5;
    }
    .upload-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86px;
      height: 86px;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      .img-delete {
        position: absolute;
        top: -3px;
        right: -4px;
        z-index: 10;
      }
    }
    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
.ant-form-item-has-error
  .iss-upload
  :deep(.ant-upload.ant-upload-select-picture-card) {
  border-color: @error-color !important;
}
.uploadBut {
  color: #5979f8;
  font-size: 16px;
  .anticon-upload,
  .anticon-upload {
    font-size: 18px;
  }
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f0f5ff;
}
</style>
