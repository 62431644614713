'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/resource/page',
  importContactUrl: '/issmart-user/contact/contactUpload',
  importActionUrl: '/issmart-user/contactActionInfo/contactActionUpload',

  getPage(code, params) {
    return http.get('/issmart-user/contact/page', { params, code });
  },
  // getTag(code, params) {
  //   return http.get('/account/tag/getTypeNameAndTag', { params, code });
  // },
  add(code, data) {
    return http.post('/issmart-user/contact', data, { code });
  },
  update(code, data) {
    return http.put('/issmart-user/contact', data, { code });
  },
  delete(code, params) {
    return http.delete('/issmart-user/contact/single', { params, code });
  },
  downloadContactTemplate(code) {
    return http.get('/issmart-user/contact/contactTemplate', {
      responseType: 'blob',
      code,
    });
  },
  downloadActionTemplate(code) {
    return http.get('/issmart-user/contactActionInfo/contactActionTemplate', {
      responseType: 'blob',
      code,
    });
  },
  export(code, params) {
    return http.post('/issmart-user/contact/contactExport', params, {
      responseType: 'blob',
      code,
    });
  },
  getdetail(code, id) {
    return http.get(`/issmart-user/contact/contactById/${id}`, { code });
  },
  getbehavior(code, params) {
    return http.get('/issmart-user/contactActionInfo/contactActionInfoPage', {
      params,
      code,
    });
  },
  getcontactTag(code, params) {
    return http.get('/account/contactTag/listByContactId', {
      params,
      code,
    });
  },
  addTag(code, data) {
    return http.post('/account/contactTag/saveValid', data, { code });
  },
  delteTag(code, params) {
    return http.delete('/account/contactTag/deleteByTagCode', { code, params });
  },
};
