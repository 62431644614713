<template>
  <a-modal
    :visible="visible"
    :title="form.disabled ? '查看' : form.id ? '编辑' : '新增'"
    :mask-closable="false"
    :width="700"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="资料目录(一级)" v-bind="validateInfos.dataLevelOne">
        <a-select
          v-model:value="form.dataLevelOne"
          placeholder="请选择资料目录(一级)"
          :disabled="form.disabled"
          @change="handleDateOneType($event)"
        >
          <a-select-option
            v-for="item in agreementList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="资料目录(二级)" v-bind="validateInfos.dataLevelTwo">
        <a-select
          v-model:value="form.dataLevelTwo"
          placeholder="请选择资料目录(二级)"
          :disabled="form.disabled"
          @change="handleDateTwoType($event)"
        >
          <a-select-option
            v-for="item in agreementTwoList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="资料目录(三级)" v-bind="validateInfos.dataLevelThree">
        <a-select
          :disabled="form.disabled"
          v-model:value="form.dataLevelThree"
          placeholder="请选择资料目录(三级)"
        >
          <a-select-option
            v-for="item in agreementThreeList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="资料名称" v-bind="validateInfos.dataName">
        <a-input
          :disabled="form.disabled"
          v-model:value="form.dataName"
          placeholder="请输入资料名称"
        />
      </a-form-item>
      <a-form-item label="资料简介" v-bind="validateInfos.dataInformation">
        <a-textarea
          :disabled="form.disabled"
          v-model:value="form.dataInformation"
          auto-size
          show-count
          :maxlength="200"
          placeholder="请输入资料简介"
        />
      </a-form-item>
      <!--       -->
      <a-form-item label="资料类型" v-bind="validateInfos.dataType">
        <a-select
          :disabled="form.disabled"
          v-model:value="form.dataType"
          placeholder="请选择资料类型"
        >
          <a-select-option
            v-for="item in dataTypeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--     上传资料  -->
      <div class="iss-spacing" v-if="form.dataType === 'PDF'">
        <!--       todo 需要做资料形式和查看的双重判断  -->
        <a-form-item
          label="上传文件"
          v-bind="form.dataType === 'PDF' ? validateInfos.dataUrl : ''"
          v-if="!form.disabled"
        >
          <iss-file-upload
            v-model:value="form.dataUrl"
            v-model:resourceSize="form.resourceSize"
            v-model:documentName="form.uploadFileName"
            list-type="text"
            accept=".jpe,.jpeg,.jpg,.png,.pdf"
          />
          <div class="item-text">请上传资料大小不超过50M,格式为pdf</div>
        </a-form-item>

        <a-form-item label="资料地址">
          <a-textarea
            :disabled="form.disabled"
            v-model:value="form.dataUrl"
            auto-size
            placeholder="请输入文件地址"
          />
        </a-form-item>
      </div>

      <!--     上传视频 根据选择的资料形式的值 来显示视频还是资料   -->
      <!--       todo 需要做资料形式和查看的双重判断   -->
      <div class="iss-spacing" v-if="form.dataType === 'MP4'">
        <a-form-item
          label="上传视频"
          v-bind="form.dataType === 'MP4' ? validateInfos.dataUrl : ''"
          v-if="!form.disabled"
        >
          <iss-video-upload v-model:value="form.video" accept=".mp4" />
          <div class="item-text">请上传视频大小不超过100M,格式为mp4/ogg</div>
        </a-form-item>
        <!--       :onkeyup="(form.formName = form.formName.replace(/\s+/g, ''))"   -->
        <a-form-item label="视频链接">
          <a-textarea
            :disabled="form.disabled"
            v-model:value="form.dataUrl"
            auto-size
            show-count
            placeholder="请输入视频链接"
          />
        </a-form-item>
      </div>
      <a-form-item label="排序" v-bind="validateInfos.sort">
        <a-input-number v-model:value="form.sort" :min="1" :max="99" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch, ref, onBeforeUnmount } from 'vue';
import { Form, Select, InputNumber } from 'ant-design-vue';
import IssVideoUpload from '@/components/videoUpload';
import IssFileUpload from '@/components/fileDownloadUpload';
import classifyApi from '@/api/classify';
// import IssImageUpload from '@/components/imageUpload';
// import { useRoute } from 'vue-router';
// import agendaApi from '@/api/agenda';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    // ARadioGroup: Radio.Group,
    // ASwitch: Switch,
    // IssImageUpload,
    IssVideoUpload,
    IssFileUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    // const route = useRoute();
    // const campaignId = route.params.campaignId;
    const editorRef = ref();
    const formRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      dataLevelOne: '',
      dataLevelTwo: '',
      dataLevelThree: '',
      dataName: '',
      language: 'Chinese',
      dataType: '',
      sort: '',
      dataInformation: null,
    });
    const state = reactive({
      agreementList: [],
      agreementTwoList: [],
      agreementThreeList: [],
      agreementDemList: [],
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
      dataTypeList: [
        { label: '文档', value: 'PDF' },
        { label: '视频', value: 'MP4' },
      ],
    });
    const getTree = () => {
      classifyApi
        .getCategoryTree('', {
          moduleCode: 'dataBase',
        })
        .then(data => {
          state.agreementList = data;
        });
    };
    const handleDateOneType = id => {
      // （一级id）
      classifyApi
        .getCategoryTree('', {
          moduleCode: 'dataBase',
          parentId: id,
        })
        .then(data => {
          form.dataLevelTwoName = '';
          form.dataLevelThreeName = '';
          state.agreementTwoList = data;
        });
    };
    const handleDateTwoType = id => {
      // （二级id）
      classifyApi
        .getCategoryTree('', {
          moduleCode: 'dataBase',
          parentId: id,
        })
        .then(data => {
          form.dataLevelThreeName = '';
          state.agreementThreeList = data;
        });
    };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      dataLevelOne: [required],
      // dataLevelTwoName: [required],
      // dataLevelThreeName: [required],
      dataName: [required],
      dataType: [required],
      // dataInformation: [required],

      video: [required],
      fileUrl: [required],
      // guestIntroduce: [
      //   required,
      //   { type: 'url', message: '请输入正确的URL地址，以http或者https开头' },
      // ],
      guestImg: [{ type: 'url', message: '请输入正确的链接格式' }],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
          handleDateOneType(form.dataLevelOne);
          handleDateTwoType(form.dataLevelTwo);
        } else {
          resetFields();
        }
      }
    );
    watch(
      () => props.visible,
      value => {
        getTree(value);
      }
    );

    // agendaApi
    //   .getResourceList(`resource:List`, {
    //     campaignId,
    //     resourceType: 'liveStream',
    //   })
    //   .then(data => {
    //     state.agreementList = data.records;
    //   });
    // agendaApi
    //   .getResourceList(`resource:List`, {
    //     campaignId,
    //     resourceType: 'demandStream',
    //   })
    //   .then(data => {
    //     state.agreementDemList = data.records;
    //   });
    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });
    const handleOk = () => {
      if (form.disabled) {
        form.disabled = false;
        context.emit('update:visible', false);
      } else {
        form.disabled = false;
        validate()
          .then(() => {
            context.emit('fnOk', toRaw(form));
          })
          .catch(res => {
            console.log(res);
            if (res.errorFields.length == 0) {
              handleOk();
            }
          });
      }
    };

    return {
      ...toRefs(state),
      form,
      getTree,
      formRef,
      handleDateOneType,
      handleDateTwoType,
      validateInfos,
      handleCancel: () => {
        form.disabled = false;
        context.emit('update:visible', false);
      },
      handleOk,
    };
  },
};
</script>
<style scoped>
.item-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 5px;
}
</style>
