'use strict';

import http from '@/utils/http';

export default {
  // 资料分页
  informationUrl: '/resource/page',

  // 新增资料
  add(code, data) {
    return http.post('/resource/addOrUpDate', data, { code });
  },
  // 编辑资料
  update(code, data) {
    return http.post('/resource/addOrUpDate', data, { code });
  },
  // 删除资料
  delete(code, params) {
    return http.delete('/resource/delete', { params, code });
  },

  // 获取下拉数据
  getCategoryTree(code, data) {
    return http.post('/category/getCategoryTree', data, {
      code,
    });
  },

  // 查询单条资料
  getfindById(code, params) {
    return http.get(`/resource/findById`, { params, code });
  },
  informationPublish(code, data) {
    return http.post('/resource/publish', data, {
      code,
    });
  },
};
